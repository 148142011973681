import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ToastContainer } from "react-toastify";
import Header from "./Header";

import "swiper/css";
import "./App.css";
import Card from "./Card";
import Map from "./Map";
import Footer from "./Footer";

const title = {
  restaurant: "근처 맛집 🍱",
  delivery: "배달 맛집 🚴‍♂️",
  dessert: "디저트 맛집 🍩",
  beauty: "미용실 💇‍♀️",
};

function App() {
  const [activeTag, setActiveTag] = useState("restaurant");
  const [data, setData] = useState([]);
  const [isMapView, setIsMapView] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 클릭된 태그에 맞는 JSON 파일을 동적으로 불러옴
        const module = await import(`./data/${activeTag}.json`);
        const _data = module.default.sort((a, b) => b.id - a.id); // id 높은 순 (추가된 데이터가 먼저 보이도록)
        setData(_data); // JSON 파일의 기본 내보내기를 설정
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    fetchData();
    setIsMapView(false);
  }, [activeTag]); // activeTag가 바뀔 때마다 데이터 불러오기

  return (
    <div className="App">
      <Header />
      <Swiper className="tag-list" slidesPerView="auto" spaceBetween={20}>
        <SwiperSlide className={activeTag === "restaurant" ? "active" : ""}>
          <button type="button" onClick={() => setActiveTag("restaurant")}>
            식당 맛집
          </button>
        </SwiperSlide>
        <SwiperSlide className={activeTag === "delivery" ? "active" : ""}>
          <button type="button" onClick={() => setActiveTag("delivery")}>
            배달 맛집
          </button>
        </SwiperSlide>
        <SwiperSlide className={activeTag === "dessert" ? "active" : ""}>
          <button type="button" onClick={() => setActiveTag("dessert")}>
            카페/디저트
          </button>
        </SwiperSlide>
        <SwiperSlide className={activeTag === "beauty" ? "active" : ""}>
          <button type="button" onClick={() => setActiveTag("beauty")}>
            뷰티/패션
          </button>
        </SwiperSlide>
      </Swiper>

      <div className="card-wrap">
        <div className="title-wrap">
          <h2>{title[activeTag]}</h2>
          {activeTag !== "delivery" && (
            <div>
              <span className="toggle-text">지도로 보기</span>
              <div className="toggle">
                <input
                  type="checkbox"
                  id="mode-toggle"
                  className="toggle__input"
                  checked={isMapView}
                  onChange={() => setIsMapView(!isMapView)}
                />
                <label htmlFor="mode-toggle" className="toggle__label"></label>
              </div>
            </div>
          )}
        </div>
        {activeTag !== "delivery" && isMapView ? (
          <Map data={data} />
        ) : (
          data.map((item) => <Card key={item.id} item={item} />)
        )}
      </div>

      <ToastContainer />
      <a
        href="https://forms.gle/A2NsTktdaQ3Q4KbP6"
        target="_blank"
        rel="noopener noreferrer"
        className="floating-button"
        title="설문지로 이동"
      >
        📮
      </a>
      <Footer />
    </div>
  );
}

export default App;
