import "./Footer.css";

function Footer() {
  return (
    <footer>
      <ul>
        <li>
          <a href="mailto: 93rlaqhal@gmail.com">📨 93rlaqhal@gmail.com</a>
        </li>
        <li>
          <a href="https://www.kko-kkuri.com">🌱 블로그</a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/%EB%B3%B4%EB%AF%B8-%EA%B9%80-126326207/">
            🤳 링크드인
          </a>
        </li>
        <li>
          <a href="https://github.com/Kbomi">🐱 https://github.com/Kbomi</a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
